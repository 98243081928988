<template>
  <div>
    <div class="computer">
      <section class="home__wrap">
        <!-- 开门效果 -->
        <div id="loader-wrapper">
          <div id="loader"></div>
          <div class="loader-section section-left"></div>
          <div class="loader-section section-right"></div>
        </div>

        <div class="home">
          <div class="section banner">
            <div class="content">
              <div class="boxs home_banner_flex" style="position: relative;">
                <!-- <el-carousel :height="bannerHeight">
                  <el-carousel-item v-for="(item,index) in homeList" :key="index">
                    <img class="cp imgb" @click="Skip(item.href)" :src="item.url" :alt="item.name">
                  </el-carousel-item>
                </el-carousel>
                <div class="track_box">
                  <el-row>
                    <el-col :span="19">
                      <el-input :placeholder="getLanguageText(languageType, '请输入单号')" v-model="trackNum" clearable></el-input>
                    </el-col>
                    <el-col :span="4" style="margin-left: 10px">
                      <el-button type="primary" @click="getTrack()" style="background-color: #005fbf;border-color: #005fbf;">{{getLanguageText(languageType, '跟踪')}} <span class="el-icon-map-location"></span></el-button>
                    </el-col>
                  </el-row>
                </div> -->
                <div class="home_banner_flex_left">
                  <h1>{{getLanguageText(languageType, '首页标题')}}</h1>
                  <p>{{getLanguageText(languageType, '首页头部介绍')}}</p>
                  <!-- <h1>Global fulfillment and 3PL services for brands and retailers</h1>
                  <p>Shipwire, a CEVA Logistics company, is where logistics meet world-class technology. With a network of global warehouses, we use advanced technology and operational expertise to optimize your order fulfillment while you focus on growing your business. Our goal is to help you thrive by providing top 3PL services to deliver your brand to the world.</p> -->
                  <el-row style="width: 50%;position: absolute;bottom: 0;">
                      <el-col :span="17">
                        <el-input :placeholder="getLanguageText(languageType, '请输入单号')" v-model="trackNum" clearable></el-input>
                      </el-col>
                      <el-col :span="3" style="margin-left: 10px">
                        <el-button id="getTrack" type="primary" @click="getTrack()" style="background-color: #005fbf;border-color: #005fbf;">{{getLanguageText(languageType, '跟踪')}} <span class="el-icon-map-location"></span></el-button>
                      </el-col>
                      <el-col :span="3" class="track_class">
                        <img @click="getTrackOpen" src="../assets/images/17track.png" alt="" :height="imgHeifht" :width="imgWidth">
                      </el-col>
                  </el-row>
                </div>
                <div class="home_banner_flex_right">
                  <div class="left_img">
                    <img src="../assets/images/home/Header_01.png" alt="">
                  </div>
                  <div class="right_img">
                    <img src="../assets/images/home/Header_02.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 公司简介 -->
          <div class="section home_synopsis">
            <div class="content">
              <div class="boxs">
                <div style="display: flex;justify-content: space-around;">
                  <div class="dec">
                    <img src="@/assets/images/dec_b_left.png" alt="">
                      <span class="big_title">{{node.companyTranslate}}</span>
                    <img src="@/assets/images/dec_b_right.png" alt="">
                  </div>
                  <div class="dec">
                    <img src="@/assets/images/dec_b_left.png" alt="">
                    <span class="big_title">{{node.newsTranslate}}</span>
                    <img src="@/assets/images/dec_b_right.png" alt="">
                  </div>
                </div>
                <div class="main">
                  <!-- <div class="main_img">
                    <img :src="about.img" :class="{'cp': about.href}" @click="Skip(about.href)" alt="">
                  </div> -->
                  <div class="main_text">
                    <div class="main_min main_body new_texts" v-html="about.aboutSynopsis"></div>
                    <div class="btn main_text_btn main_body" @click="goMore(4, '/about')">{{node.viewMoreTranslate}}</div>
                  </div>
                  <div class="new">
                    <vue-seamless-scroll :data="newsList.newsList_all" :class-option="seamlessScrollOption" id="marqueebox" >
                      <div class="new_item" v-for="(item, index) in newsList.newsList_all" :key="index">
                        <div class="new_item_img">
                          <img :src="item.imgs" class="cp" @click="newsDetail(item)" alt="">
                        </div>
                        <div class="new_item_text">
                          <div class="title line_clamp small_title" style="font-size: 16px !important;" @click="newsDetail(item)">{{item.title}}</div>
                          <div class="new_texts main_body">{{item.bizSynopsis}}</div>
                          <div class="main_releaseTimes main_body">{{node.releaseTimeTranslate}}: {{item.createTime}}</div>
                        </div>
                      </div>
                    </vue-seamless-scroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 主营业务 -->
          <div class="section home_business">
            <div class="content">
              <div class="boxs">
                <div class="dec">
                    <img src="@/assets/images/dec_b_left.png" alt="">
                    <span class="big_title">{{node.businessTranslate}}</span>
                    <img src="@/assets/images/dec_b_right.png" alt="">
                </div>
                <div class="home_business_car">
                  <el-carousel type="card" class="car_h"  style="clear:both" :height="carHeight" :interval="4000">
                    <el-carousel-item v-for="(item, index) in bizList" :key="index">
                      <div class="carousel">
                        <img :src="imgTransition(JSON.parse(item.imgs)[0].urlName)" :class="{'cp': JSON.parse(item.imgs)[0].href}" @click="Skip(JSON.parse(item.imgs)[0].href)" alt="">
                        <div class="title pl20 small_title" @click="routingHop(item.businessCategory)">{{item.businessCategory | labelMapper(businessCategory, ['name','code'])}}</div>
                        <div class="text main_body">{{item.bizSynopsis}}</div>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
          </div>
          <!-- 新闻资讯 -->
          <!-- <div class="section home_journalism">
            <div class="content">
              <div class="boxs">
                <div class="dec">
                    <img src="@/assets/images/dec_b_left.png" alt="">
                    <span class="big_title">{{node.newsTranslate}}</span>
                    <img src="@/assets/images/dec_b_right.png" alt="">
                </div>
                <div class="main">
                  <div class="main_left">
                    <div class="main_l_img">
                      <img :src="newsList.newsList_0.imgs" class="cp" @click="newsDetail(newsList.newsList_0)" style="width:100%; height: 100%" alt="">
                    </div>
                    <div class="main_l_theme">
                      <div class="title line_clamp small_title" @click="newsDetail(newsList.newsList_0)">{{newsList.newsList_0.title}}</div>
                      <div class="main_text main_body">{{newsList.newsList_0.bizSynopsis}}</div>
                      <div class="main_releaseTime main_body">{{node.releaseTimeTranslate}}: {{newsList.newsList_0.createTime}}</div>
                    </div>
                  </div>
                  <div class="main_right">
                    <div class="main_r_theme" v-for="(item, index) in newsList.newsList_1" :key="index">
                      <div class="main_r_img">
                        <img :src="item.imgs" class="cp" @click="newsDetail(item)" style="width:100%; height: 100%" alt="">
                      </div>
                      <div class="main_r_tt">
                        <div class="title line_clamp small_title" @click="newsDetail(item)">{{item.title}}</div>
                        <div class="main_texts main_body">{{item.bizSynopsis}}</div>
                        <div class="main_releaseTimes main_body">{{node.releaseTimeTranslate}}: {{item.createTime}}</div>
                      </div>
                    </div>
                    <div class="btn fl main_body" @click="goMore(3, '/information')">{{node.viewMoreTranslate}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- 联系我们 -->
          <div class="section home_relation">
            <div class="content">
              <!-- <div class="boxs">
                <div class="dec">
                    <img src="@/assets/images/dec_b_left.png" alt="">
                    <span>{{node.contactTranslate}}</span>
                    <img src="@/assets/images/dec_b_right.png" alt="">
                </div>
                <div class="home_relation_main">
                  <div class="home_relation_mains" :class="{'home_relation_bor': item.id == 2}" v-for="item in relationList" :key="item.id">
                    <i :class="item.icon" class="icon"></i>
                    <div style="margin-left:20px">
                      <span class="relation_name">{{item.name}}</span>
                      <span class="relation_text">{{item.text}}</span>
                    </div>
                  </div>
                </div>
              </div> -->
              <Footer class="footer"/>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="phone">
      <!-- 首页标题 -->
      <div class="section">
        <div class="content sec_header">
          <div class="boxs header_main">
            <h1>{{getLanguageText(languageType, '首页标题')}}</h1>
            <p>{{getLanguageText(languageType, '首页头部介绍')}}</p>
            <div class="phone_banner">
              <div class="left_img">
                <img src="../assets/images/home/Header_01.png" alt="">
              </div>
              <div class="right_img">
                <img src="../assets/images/home/Header_02.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 公司简介 -->
      <div class="content">
        <div class="boxs p2_0vh">
          <div class="p_dec">
            <img src="@/assets/images/dec_b_left.png" alt="">
              <span>{{node.companyTranslate}}</span>
            <img src="@/assets/images/dec_b_right.png" alt="">
          </div>
          <div class="sec_main">
            <div v-html="about.aboutSynopsis"></div>
            <div class="btn mt2vh" @click="goMore(4, '/about')">{{node.viewMoreTranslate}}</div>
          </div>
        </div>
      </div>
      <!-- 新闻资讯 -->
      <div class="content">
        <div class="boxs p2_0vh">
          <div class="p_dec">
            <img src="@/assets/images/dec_b_left.png" alt="">
              <span>{{node.newsTranslate}}</span>
            <img src="@/assets/images/dec_b_right.png" alt="">
          </div>
          <div class="phone_new">
            <div class="new_item" v-for="(item, index) in newsList.newsList_all" :key="index">
              <div class="new_item_img">
                <img :src="item.imgs" class="cp" @click="newsDetail(item)" alt="">
              </div>
              <div class="new_item_text">
                <div class="title line_clamp small_title" style="font-size: 16px !important;" @click="newsDetail(item)">{{item.title}}</div>
                <div class="new_texts main_body">{{item.bizSynopsis}}</div>
                <div class="new_time">{{node.releaseTimeTranslate}}: {{item.createTime}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 主营业务 -->
      <div class="home_business">
        <div class="content">
          <div class="boxs">
            <div class="p_dec">
                <img src="@/assets/images/dec_b_left.png" alt="">
                <span>{{node.businessTranslate}}</span>
                <img src="@/assets/images/dec_b_right.png" alt="">
            </div>
            <div class="home_business_car">
              <div v-for="(item, index) in bizList" :key="index">
                <div class="carousel">
                  <img :src="imgTransition(JSON.parse(item.imgs)[0].urlName)" :class="{'cp': JSON.parse(item.imgs)[0].href}" @click="Skip(JSON.parse(item.imgs)[0].href)" alt="">
                  <div class="title pl20" @click="routingHop(item.businessCategory)">{{item.businessCategory | labelMapper(businessCategory, ['name','code'])}}</div>
                  <div class="text">{{item.bizSynopsis}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="home_relation">
        <div class="content">
          <div class="boxs">
            <div class="p_dec">
                <img src="@/assets/images/dec_b_left.png" alt="">
                <span>{{node.contactTranslate}}</span>
                <img src="@/assets/images/dec_b_right.png" alt="">
            </div>
            <div class="home_relation_main">
              <div class="home_relation_mains" :class="{'home_relation_bor': item.id == 2}" v-for="item in relationList" :key="item.id">
                <i :class="item.icon" class="icon"></i>
                <div class="home_relation_text" style="margin-left:20px">
                  <span class="relation_name">{{item.name}}</span>
                  <span class="relation_text">{{item.text}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <Footer class="home_footer"/> -->
        </div>
      </div>
    </div>
    <phone-menu></phone-menu>
  </div>
</template>

<script>
  import Footer from '../components/Footer.vue'
  import phoneMenu from '../components/phoneMenu.vue'
  import 'swiper/dist/idangerous.swiper.css'
  import Swiper from 'swiper/dist/idangerous.swiper.js'
  import { sitenewsList } from "@/api/index"
  import vueSeamlessScroll from 'vue-seamless-scroll'  // vue2引入方式
  export default {
    name: '',
    data() {
      return {
        trackNum: '',
        carHeight: '600px', // 走马灯高度
        carHeights: '55vw', // 走马灯高度
        bannerHeight: '600px',  // banner走马灯高度
        ifPhone: false,
        navigators: '',
        newList: [],
        imgHeifht: '40px',
        imgWidth: '80px',
      }
    },
    components: {
      Footer,
      phoneMenu,
      vueSeamlessScroll
    },
    computed: {
      node(){
        return this.$store.state.node
      },
      newsList() {
        return this.$store.state.newsList
      },
      bizList() {
        return this.$store.state.bizList
      },
      homeList() {
        return this.$store.state.homeList 
      },
      businessCategory() {
        return this.$store.state.businessCategory
      },
      about() {
        return this.$store.state.about
      },
      relationList() {
        return this.$store.state.relationList
      },
      languageType() {
        return this.$store.state.languageType
      },
      seamlessScrollOption () {
        return {
          step: 0.1, // 数值越大速度滚动越快
          limitMoveNum: this.newList.length, // 开始无缝滚动的数据量
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
        }
      }
    },
    methods: {
      // 打开17track
      getTrackOpen() {
        window.open('https://www.17track.net/carriers/190396')
      },
      getList() {
        let params = {
          current: 1,
          size: 9999,
          asc: false,
          orderByField: 'id desc',
          querydata: {
            businessCategory: 1,
            siteCode: this.$store.state.siteCode,
          }
        }
        params.querydata = JSON.stringify(params.querydata)
        sitenewsList(params).then((res) => {
          if(res && res.code == 0) {
            this.newList = []
            res.data.records.map((item, index) => {
              if(index < 5) {
                this.newList.push({
                  id: item.id,
                  title: item.title,
                  bizSynopsis: item.bizSynopsis,
                  createTime: item.createTime.slice(0, 11),
                  imgs: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
                  href: JSON.parse(item.imgs)[0].href
                })
              }
            })
          }
        }).catch(err => {
          console.log('err', err);
        })
      },
      getTrack() {
        if(this.trackNum) {
          this.$store.commit('getTrackNum', this.trackNum)
          this.$router.push({ path: '/track'})
        } else {
          this.$message.error(this.getLanguageText(this.languageType, '请输入单号'));
        }
      },
      // 主营业务跳转
      routingHop(id) {
        this.$router.push({ path: '/business/' + id})
        sessionStorage.setItem('menu', '2.'+id)
      },
      Skip(url) {
        if(url) {
          window.open(url)
        }
      },
      viewDetail(id){
        this.$router.push(`/newsDetail?id=${id}`)
      },
      goMore(id, url) {
        this.$store.commit('setId', id)
        sessionStorage.setItem('currId', id)
        sessionStorage.setItem('menu', id)
        this.$router.push(url)
      },
      // 图片路径转换
      imgTransition(str) {
        return this.$store.state.domainImg + str.slice(0, 1) + '/' + str.slice(1, 2) + '/' + str
      },
      // 新闻详情
      newsDetail(data) {
        window.open('#/newsDetail?id=' + data.id)
      },
    },
    created () {
      // this.getList()
    },
    mounted() {
      
      // 媒体查询
      let result = window.matchMedia('(max-width: 1300px)');
      this.bannerHeight = document.getElementsByClassName('banner')[0].offsetHeight - 20 + 'px'
      let maxResult = window.matchMedia('(max-width: 980px)');
      if (maxResult.matches) {
        this.bannerHeight = '80vw'
        this.ifPhone = false
      } else {
        this.ifPhone = true
        this.carHeight = document.getElementsByClassName('car_h')[0].offsetHeight - 90 + 'px'
      }

      new Swiper('.swiper-container', {
        autoplay: 3000, //可选选项，自动滑动
        loop: true, //可选选项，开启循环
        mode: 'horizontal',
        slidesPerView: 1,
        // noSwiping : true,
        pagination: '.pagination',
        paginationClickable: true,
        onSlideClick: swiper => {
          this.viewDetail(swiper.clickedSlide.dataset.id)
        }
      })
      let getTrackDom = document.getElementById('getTrack')
      this.imgHeifht = getTrackDom.offsetHeight + 'px'
      this.imgWidth = getTrackDom.offsetWidth + 'px'
    },
    watch: {
      languageType(newVal, oldVal) {
        // this.getList()
      }
    },
    destroyed() {
    }
  }
</script>

<style lang="scss" scoped>
  .phone_new {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 60vh;
    overflow-y: auto;
    .new_item {
      width: 100%;
      display: flex;
      margin-bottom: 2vh;
    }
    .new_item_img {
      width: 13vh;
      height: 13vh;
      img {
        width: 13vh;
        height: 13vh;
      }
    }
    .new_item_text {
      margin-left: 2vh;
    }
    .new_texts {
      font-family: "Open Sans" !important;
      color: #444;
      text-align: left;
      font-size: 1.6vh !important;
      text-indent: 2em;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .new_time {
      font-family: "Open Sans" !important;
      color: #444;
      font-size: 1.6vh;
    }
  }
  .sec_main {
    /deep/ p {
      font-size: 1.8vh;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: -0.1px;
      text-align: left;
      color: #444;
    }
    .btn {
      border-radius: 0.2vh;
      background-color: #4091eb;
      font-size: 1.3vh;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      padding: 1.2vh 2vh;
      height: 3.9vh;
      width: 100%;
    }
  }
  .p2_0vh {
    padding: 2vh 0;
  }
  .mt2vh {
    margin-top: 2vh;
  }
  .sec_header {
    background-color: #d3e8fd;
    padding: 5vh 0 6vh;
    .header_main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin-bottom: 1vh;
      h1 {
        font-size: 5vh;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.28;
        letter-spacing: normal;
        text-align: center;
        color: #231f20;
        font-family: "Open Sans";
        margin-bottom: 2vh;
      }
      p {
        font-size: 2vh;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #444;
        font-family: "Open Sans";
      }
      .phone_banner {
        width: 100vw;
        height: 60vw;
        margin: 0 2vh;
        position: relative;
        margin-top: 3.6vh;
        .left_img {
          width: 66%;
          position: absolute;
          right: 2vh;
          img {
            width: 100%;
          }
        }
        .right_img {
          position: absolute;
          width: 50%;
          top: 2vh;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .home_banner_flex {
    display: flex;
    color: #000;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 40px;
    box-sizing: border-box;
    width: 80%;
    .home_banner_flex_left {
      width: 60%;
      padding: 20px;
      box-sizing: border-box;
      h1 {
        box-sizing: border-box;
        font-size: 50px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        color: #231f20;
        margin-bottom: 20px;
        text-align: left;
      }
      p {
        box-sizing: border-box;
        max-height: 220px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: "Open Sans";
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #444;
        margin-bottom: 40px;
        text-align: left;
      }
    }
    .home_banner_flex_right {
      width: 40%;
      position: relative;
      max-width: 600px;
      margin-left: 200px;
      .left_img {
        width: 535px;
        img {
          width: 100%;
        }
      }
      .right_img {
        position: absolute;
        top: 22px;
        left: -175px;
        width: 340px;
        img {
          width: 100%;
        }
      }
    }
  }
  .new {
    width: 49%;
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow-y: auto;
    .new_item {
      width: 100%;
      display: flex;
      margin-bottom: 20px;
    }
    .new_item_img {
      width: 130px;
      height: 130px;
      img {
        width: 130px;
        height: 130px;
      }
    }
    .new_item_text {
      margin-left: 20px;
    }
    .new_texts {
      font-family: "Open Sans" !important;
      color: #444;
      text-align: left;
      font-size: 16px !important;
      text-indent: 2em;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
  .new::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .new {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
  }
  .track_box {
    width: 600px;
    height: 60px;
    background-color: #fff;
    border-radius: 15px;
    position: relative;
    top: -96px;
    z-index: 10;
    left: 20px;
    padding: 10px;
    overflow: hidden;
  }
  @media screen and (max-width: 980px) {
    ::v-deep .el-carousel__item {
      width: 101%;
    }
  }
  .phone_banner {
    width: 100vw;
    height: 80vw;
  }
  ::v-deep .el-carousel__indicators--horizontal {
    bottom: 40px;
  }
  ::v-deep .el-carousel__item {
    background-color: #fff !important;
    border: 0 !important;
  }
  .home__wrap {
    color: #fff;
    text-align: center;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .track_class {
    border: 1px solid rgb(0, 95, 191);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    margin-left: 10px;
  }
</style>
